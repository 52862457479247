import { type IAction } from '../../@types';
import { CLEAR_REQUESTER, SET_DATA, SET_INITIAL_LOAD, SET_PARAMS, SET_REQUEST, SET_TOTAL } from '../types/requester';

export interface IRequestParams {
  skip: number;
  take: number;
  sort: string;
  order: 'ASC' | 'DESC';
}

export interface IRequesterStore {
  requester: {
    initialLoaded: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    request: Function | null;
    total: number;
    data: any[];
    params: IRequestParams;
  };
}

const initialState: IRequesterStore = {
  requester: {
    request: null,
    data: [],
    total: 0,
    initialLoaded: false,
    params: {
      skip: 0,
      take: 20,
      order: 'DESC',
      sort: 'createdAt',
    },
  },
};

const requesterReducer = (state: IRequesterStore = initialState, { type, payload }: IAction<any>): IRequesterStore => {
  switch (type) {
    case SET_REQUEST: {
      return {
        ...state,
        requester: {
          ...state.requester,
          initialLoaded: false,
          request: payload.request,
        },
      };
    }
    case SET_INITIAL_LOAD: {
      return {
        ...state,
        requester: {
          ...state.requester,
          initialLoaded: payload.initialLoaded,
        },
      };
    }
    case SET_DATA: {
      return {
        ...state,
        requester: {
          ...state.requester,
          data: payload.data,
        },
      };
    }
    case SET_TOTAL: {
      return {
        ...state,
        requester: {
          ...state.requester,
          total: payload.total,
        },
      };
    }
    case SET_PARAMS: {
      return {
        ...state,
        requester: {
          ...state.requester,
          params: {
            order: payload.order || state.requester.params.order,
            skip: Number.isInteger(payload.skip) ? payload.skip : state.requester.params.skip,
            sort: payload.sort || state.requester.params.sort,
            take: Number.isInteger(payload.take) ? payload.take : state.requester.params.take,
          },
        },
      };
    }
    case CLEAR_REQUESTER: {
      return {
        ...state,
        requester: {
          data: [],
          total: 0,
          request: null,
          initialLoaded: false,
          params: {
            skip: 0,
            take: 20,
            order: 'DESC',
            sort: 'createdAt',
          },
        },
      };
    }
    default:
      return state;
  }
};

export default requesterReducer;
