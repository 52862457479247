import { useCallback, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Container from '../components/layout/container';
import AdminHeader from '../components/layout/header/admin';
import Sidebar from '../components/layout/sidebar';
import MobileSidebar from '../components/layout/sidebar/mobile';
import ChatBot from '../pages/chat-bot';
import { setData, setInitialLoad, setParams, setTotal } from '../store/actions/requester';
import { requesterSelector } from '../store/selectors/requester';

const AdminLayout = () => {
  const dispatch = useDispatch();
  const bottomDivRef = useRef(null);
  const requester = useSelector(requesterSelector);

  const doRequest = useCallback(async () => {
    if (requester.request && requester.params) {
      try {
        const data = await requester.request(requester.params);
        const nextSkip = requester.params.skip + 20;
        dispatch(setData({ data: [...requester.data, ...data.list] }));
        dispatch(setTotal({ total: data.totalCount }));
        dispatch(setInitialLoad({ initialLoaded: true }));
        dispatch(setParams({ skip: nextSkip < data.totalCount ? nextSkip : data.totalCount }));
      } catch (error) {
        console.log(error);
      }
    }
  }, [requester, dispatch]);

  useEffect(() => {
    (() => {
      if (!requester.initialLoaded) {
        doRequest();
      }
    })();
  }, [requester.initialLoaded, doRequest]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting && requester.request && requester.params.skip < requester.total) {
          await doRequest();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (bottomDivRef.current) {
      observer.observe(bottomDivRef.current);
    }

    return () => {
      if (bottomDivRef.current) {
        observer.unobserve(bottomDivRef.current);
      }
    };
  }, [requester, bottomDivRef, dispatch, doRequest]);

  return (
    <Container>
      <AdminHeader />
      <div className="w-full h-full flex flex-row xl:items-start justify-start overflow-auto mobile:mt-[90px]">
        <Sidebar className=" mobile:hidden" />
        <div className="relative w-full overflow-auto p-[30px] mobile:!p-[15px] medium:p-[20px] bg-[#F9F9F9] mobile:h-[calc(100vh_-_180px)] h-[calc(100vh_-_90px)]">
          <Outlet />
          <div ref={bottomDivRef} className="h-[1px] w-full"></div>
          <ChatBot />
        </div>
      </div>
      <MobileSidebar className="hidden mobile:flex mobile:h-[90px]" />
    </Container>
  );
};

export default AdminLayout;
