import { type IAction } from '../../@types';
import { CLEAR_REQUESTER, SET_DATA, SET_INITIAL_LOAD, SET_PARAMS, SET_REQUEST, SET_TOTAL } from '../types/requester';

export interface SetRequestPayload {
  // eslint-disable-next-line @typescript-eslint/ban-types
  request: Function | null;
}

export interface SetInitialLoadPayload {
  initialLoaded: boolean;
}

export interface SetDataPayload {
  data: any[];
}

export interface CallRequestPayload {
  reFetch: boolean;
}

export interface SetTotalPayload {
  total: number;
}

export interface SetParamsPayload {
  skip?: number;
  take?: number;
  sort?: string;
  order?: 'ASC' | 'DESC';
}

export const setRequest = (payload: SetRequestPayload): IAction<any> => ({
  type: SET_REQUEST,
  payload,
});

export const setInitialLoad = (payload: SetInitialLoadPayload): IAction<any> => ({
  type: SET_INITIAL_LOAD,
  payload,
});

export const setData = (payload: SetDataPayload): IAction<any> => ({
  type: SET_DATA,
  payload,
});

export const setParams = (payload: SetParamsPayload): IAction<any> => ({
  type: SET_PARAMS,
  payload,
});

export const setTotal = (payload: SetTotalPayload): IAction<any> => ({
  type: SET_TOTAL,
  payload,
});

export const clearRequester = (): IAction<any> => ({
  type: CLEAR_REQUESTER,
});
