import { type IAction, type Nullable } from '../../@types';
import { type IUser } from '../actions/auth';
import { SET_CURRENT_USER, SIGN_IN } from '../types/auth';

export interface IAuthStore {
  user: Nullable<IUser>;
}

const initialState = {
  user: null,
};

const authReducer = (state: IAuthStore = initialState, { type, payload }: IAction<any>): IAuthStore => {
  switch (type) {
    case SIGN_IN: {
      const { token, rememberCheck, user } = payload;

      if (rememberCheck) localStorage.setItem('access_token', token);
      else sessionStorage.setItem('access_token', token);

      return {
        ...state,
        user,
      };
    }

    case SET_CURRENT_USER: {
      return {
        ...state,
        user: payload?.user,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
