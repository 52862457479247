import { combineReducers } from 'redux';

import authReducer, { type IAuthStore } from './auth';
import chatBotReducer, { type IChatBot } from './chatBot';
import companyReducer, { type ICompanyStore } from './company';
import headerReducer, { type IHeaderStore } from './header';
import invoiceReducer, { type IInvoiceStore } from './invoice';
import loaderReducer, { type ILoaderStore } from './loader';
import requesterReducer, { type IRequesterStore } from './requester';

export interface ICombinedState {
  auth: IAuthStore;
  header: IHeaderStore;
  invoice: IInvoiceStore;
  company: ICompanyStore;
  chatBot: IChatBot;
  loader: ILoaderStore;
  requester: IRequesterStore;
}

export default combineReducers({
  auth: authReducer,
  header: headerReducer,
  loader: loaderReducer,
  invoice: invoiceReducer,
  company: companyReducer,
  chatBot: chatBotReducer,
  requester: requesterReducer,
});
